import PropTypes from 'prop-types';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import Icon from 'shopper/components/Icon';
import Text from 'shopper/components/Text';

import Authors from 'components/Authors';
import LikeButton from 'components/OfferLikes/LikeButton';
import UserAvatar from 'components/UserAvatar';

import { getShortenedTimeTo } from 'lib/date';

const OfferGridCardFooter = ({
  authors = [],
  className,
  offerComments,
  offerPublished,
  offerId,
  gaEventCategory,
  likesCount,
  userId,
  userName,
  userPhoto,
}) => (
  <div
    className={twMerge(
      'mt-3 flex w-full items-center justify-between',
      className
    )}
  >
    <div className="flex w-full flex-row flex-nowrap items-center justify-between">
      <div className="mr-4 flex w-full flex-row items-center gap-x-1">
        {authors.length > 0 ? (
          <Authors
            authors={authors}
            className="col-start-1 row-span-2 row-start-1 md:row-span-1"
            firstAuthorId={userId}
            size="size6"
          />
        ) : (
          <UserAvatar
            size="size6"
            userId={userId}
            userName={userName}
            userPhoto={userPhoto}
          />
        )}
        <div className="ml-1 flex w-full flex-col">
          <Text
            className="col-start-2 row-start-1 line-clamp-1 w-full truncate text-ellipsis break-all text-neutral-low-500 dark:text-neutral-high-300 md:max-w-28"
            size="size3"
          >
            {userName}
          </Text>
          <Text
            className="col-start-2 row-start-2 text-neutral-low-100 dark:text-neutral-high-300"
            size="size3"
            suppressHydrationWarning
          >
            {`Há ${getShortenedTimeTo(offerPublished)}`}
          </Text>
        </div>
      </div>
      <div className="flex items-center justify-center text-primary-400 dark:text-primary-200">
        <div className="flex items-center gap-x-1">
          <LikeButton
            className="mt-px border-none bg-transparent hover:bg-transparent dark:bg-transparent [&>span]:text-rd-primary-700 dark:[&>span]:text-primary-200 [&>svg]:text-rd-primary-700 dark:[&>svg]:text-primary-200"
            gaEventCategory={gaEventCategory}
            likesCount={likesCount}
            offerId={offerId}
            size="size4"
            type="primary"
            ghost
          />
          <hr className="mx-2 block h-5 w-px bg-neutral-high-300 dark:bg-neutral-low-300" />
          <Icon
            className="mt-px text-rd-primary-700 dark:text-primary-200"
            name="comments-filled"
            size="size3"
          />
          <Text
            className="text-rd-primary-700 dark:text-primary-200"
            size="size2"
          >
            {offerComments}
          </Text>
        </div>
      </div>
    </div>
  </div>
);

OfferGridCardFooter.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  gaEventCategory: PropTypes.string.isRequired,
  offerComments: PropTypes.number.isRequired,
  offerId: PropTypes.number.isRequired,
  offerPublished: PropTypes.string.isRequired,
  likesCount: PropTypes.number.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
};

export default memo(OfferGridCardFooter);
